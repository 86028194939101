import * as Dialog from '@radix-ui/react-dialog'
import { useAuth } from '@upper/auth'
import { RoleKey } from '@upper/graphql/freelancer'
import {
  CrossIcon,
  HamburgerIcon,
  HelpIcon,
  HourglassIcon,
  Logo,
  LogoutIcon,
  NavigationDashboardIcon,
  NavigationMyJobsIcon,
  NavigationMyReferralsIcon,
  NavigationOpenPositionsIcon,
  NavigationPerksIcon,
} from '@upper/icons'
import { classNames } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { NavigationItem } from './navigation-item'
import { SidebarAvailabilityButton } from './sidebar-availability-button'
import { SidebarRateButton } from './sidebar-rate-button'
import { useTalentStatus } from './with-talent-status'

export default function MobileHeader({
  menuContent,
  onUpdateAvailability,
  onUpdateRate,
}: {
  menuContent?: React.ReactNode
  onUpdateAvailability?: () => void
  onUpdateRate?: () => void
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const { ref, inView } = useInView({
    threshold: 1,
    initialInView: true,
  })
  const isHeaderSticky = !inView
  const router = useRouter()
  const { user, logout, userHasRole } = useAuth()
  const {
    isTalentActive,
    availability,
    nextAvailability,
    hourlyRate,
    hasFreelancerAppAccess,
  } = useTalentStatus()

  React.useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuOpen(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <div
      ref={ref}
      className={classNames(
        'sticky -top-px z-10 py-8 md:hidden',
        isHeaderSticky && 'pointer-events-none'
      )}
    >
      <div className="px-8">
        <div className="flex items-center justify-between">
          <Link href="/">
            <Logo
              className={classNames(
                'text-blue h-auto w-[98px] transition-opacity',
                isHeaderSticky && 'opacity-0'
              )}
            />
          </Link>
          <button
            type="button"
            className={classNames(
              'focus:ring-blue-light inline-flex h-11 w-11 items-center justify-center rounded-md text-white shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2',
              isHeaderSticky && 'pointer-events-auto'
            )}
            style={{
              background:
                'linear-gradient(138.32deg, #0128AB -0.62%, #00EBD3 235.4%)',
            }}
            onClick={() => {
              setIsMenuOpen(true)
            }}
          >
            <HamburgerIcon />
          </button>
        </div>
      </div>

      <Dialog.Root
        open={isMenuOpen}
        onOpenChange={(open) => {
          setIsMenuOpen(open)
        }}
      >
        <Dialog.Content
          className="pb-safe state-open:animate-fade-in state-closed:animate-fade-out fixed inset-0 flex flex-col text-white"
          style={{
            background:
              'linear-gradient(147.11deg, #0128AB -5.54%, #00EBD3 141.21%)',
          }}
        >
          <div className="py-8">
            <div className="px-8">
              <div className="flex items-center justify-between">
                <Link href={'/'}>
                  <Logo className="h-auto w-[98px]" />
                </Link>
                <Dialog.Close className="focus:ring-offset-blue focus:ring-blue-light inline-flex h-11 w-11 items-center justify-center rounded-md border border-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <CrossIcon className="h-5 w-5" />
                </Dialog.Close>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            <div className="mx-auto max-w-md px-8">
              {menuContent ? (
                menuContent
              ) : (
                <nav className="flex flex-col space-y-3">
                  {!userHasRole(RoleKey.StudioTalent) && (
                    <NavigationItem
                      icon={<NavigationDashboardIcon />}
                      label="Dashboard"
                      href="/"
                    />
                  )}
                  {!userHasRole(RoleKey.StudioTalent) && (
                    <NavigationItem
                      icon={<NavigationMyJobsIcon />}
                      label="My Jobs"
                      href="/my-jobs"
                    />
                  )}
                  {!userHasRole(RoleKey.StudioTalent) && (
                    <NavigationItem
                      icon={<NavigationOpenPositionsIcon />}
                      label="Open Positions"
                      href="/open-positions"
                      isLocked={!isTalentActive}
                    />
                  )}
                  {!userHasRole(RoleKey.StudioTalent) && (
                    <NavigationItem
                      icon={<NavigationMyReferralsIcon />}
                      label="My Referrals"
                      href="/my-referrals"
                      isLocked={!isTalentActive}
                    />
                  )}
                  {!userHasRole(RoleKey.StudioTalent) && (
                    <NavigationItem
                      icon={<NavigationPerksIcon />}
                      label="Perks"
                      href="/perks"
                      isLocked={!isTalentActive}
                    />
                  )}
                  <NavigationItem
                    icon={<HourglassIcon />}
                    label="Timesheet"
                    href="/timesheet"
                    isLocked={
                      !(
                        userHasRole(RoleKey.StudioTalent) ||
                        (hasFreelancerAppAccess && isTalentActive)
                      )
                    }
                  />

                  {isTalentActive && (
                    <div className="-mx-2">
                      <SidebarRateButton
                        rate={hourlyRate}
                        onClick={() => {
                          setIsMenuOpen(false)
                          onUpdateRate?.()
                        }}
                      />
                    </div>
                  )}
                  {isTalentActive && (
                    <div className="-mx-2">
                      <SidebarAvailabilityButton
                        availability={availability}
                        nextAvailability={nextAvailability}
                        onClick={() => {
                          setIsMenuOpen(false)
                          onUpdateAvailability?.()
                        }}
                      />
                    </div>
                  )}
                </nav>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="mx-auto max-w-md px-8">
              <div className="border-t border-white/20 py-6">
                <div className="space-x-24">
                  <a
                    href="https://www.notion.so/Talent-Handbook-5634b17325a34d88b648fdc3abda5e6d#382c433417cb46119ee575ba18fa01ba"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center py-2"
                  >
                    <HelpIcon className="h-4 w-4 opacity-50" />
                    <span className="ml-3">Help</span>
                  </a>
                  <button
                    type="button"
                    className="inline-flex items-center py-2"
                    onClick={() => {
                      logout()
                    }}
                  >
                    <LogoutIcon className="h-4 w-4 opacity-50" />
                    <span className="ml-3">Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  )
}
