import { LockIcon } from '@upper/icons'
import { classNames, Tooltip } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

export function NavigationItem({
  icon,
  label,
  href,
  isLocked = false,
  hasActivity,
  isNavigationCollapsed = false,
}: {
  icon: React.ReactElement
  label: string
  href: string
  isLocked?: boolean
  hasActivity?: boolean
  isNavigationCollapsed?: boolean
}) {
  const router = useRouter()
  const isActive = router.asPath === href

  if (isNavigationCollapsed) {
    return (
      <Tooltip
        content={label}
        contentProps={{
          side: 'right',
          sideOffset: 12,
        }}
        delayDuration={0}
        triggerProps={{ asChild: true }}
      >
        <span style={{ WebkitAppearance: 'none' }}>
          <Link
            href={href}
            className={classNames(
              'group relative flex h-10 w-10 items-center justify-center hover:text-white focus:text-white',
              isActive ? 'text-white' : 'text-white/50'
            )}
          >
            {React.cloneElement(icon, {
              className: classNames('w-10 h-10'),
            })}
            {isLocked && (
              <LockIcon
                className={classNames(
                  'group-hover:text-yellow group-focus:text-yellow absolute right-0 top-3 h-4 w-4 text-white',
                  isActive && 'text-yellow'
                )}
              />
            )}
            {hasActivity && (
              <div className="bg-red absolute right-0 top-3 h-3 w-3 rounded-full border border-white/50" />
            )}
          </Link>
        </span>
      </Tooltip>
    )
  }

  return (
    <Link
      href={href}
      className={classNames(
        'group flex items-center hover:text-white focus:text-white',
        isActive ? 'text-white' : 'text-white/50'
      )}
    >
      {React.cloneElement(icon, {
        className: classNames('w-10 h-10 flex-shrink-0 mr-2'),
      })}
      <span className="flex-1 whitespace-nowrap">{label}</span>
      {isLocked && (
        <LockIcon
          className={classNames(
            'group-hover:text-yellow group-focus:text-yellow mr-1 h-4 w-4',
            isActive && 'text-yellow'
          )}
        />
      )}
      {hasActivity && (
        <div className="bg-red mr-1.5 h-3 w-3 rounded-full border border-white/50" />
      )}
    </Link>
  )
}
