import { EditIcon } from '@upper/icons'
import { Tooltip } from '@upper/ui'
import React, { HTMLAttributes, ReactNode, forwardRef } from 'react'

interface Props {
  as?: 'a' | 'button'
  label: string | ReactNode
  isCollapsed?: boolean
  icon?: ReactNode
  tooltip?: string | ReactNode
  canEdit?: boolean
}

export type SidebarCollapsableButtonProps = Props &
  HTMLAttributes<HTMLButtonElement>

export const SidebarCollapsableButton = forwardRef<
  HTMLButtonElement,
  SidebarCollapsableButtonProps
>(
  (
    {
      as = 'button',
      icon,
      label,
      tooltip,
      isCollapsed = false,
      canEdit = false,
      ...props
    },
    ref
  ) => {
    const wrapper = (content: ReactNode) => (
      <span className="bg-gray-darkest/30 flex h-10 w-full items-center gap-3 rounded px-3">
        {content}
      </span>
    )
    const smallMarkup = (
      <span className="bg-gray-darkest/30 grid h-10 w-full place-content-center rounded">
        {icon}
      </span>
    )

    const largeMarkup = wrapper(
      <>
        {icon}
        {label}
        {canEdit && (
          <div className="ml-auto">
            <EditIcon />
          </div>
        )}
      </>
    )

    const markup = React.createElement<any>(
      as,
      { ...props, className: 'w-full' },
      <>
        {isCollapsed ? (
          <div className="group relative flex flex-col items-center">
            {smallMarkup}
            <div className="absolute bottom-0 left-0 hidden w-[200px] rounded bg-[#206E98] group-hover:block">
              {largeMarkup}
            </div>
          </div>
        ) : (
          largeMarkup
        )}
      </>
    )

    const tooltipMarkup = tooltip ? (
      <Tooltip ref={ref} triggerProps={{ asChild: true }} content={tooltip}>
        {markup}
      </Tooltip>
    ) : (
      markup
    )

    return tooltipMarkup
  }
)
